.top_select[data-v-06ce86ff] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.auto_content .auto_content_size .each[data-v-06ce86ff] {
  width: 130px !important;
}
.item_icon[data-v-06ce86ff] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.item_icon i[data-v-06ce86ff] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-06ce86ff] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.text_width[data-v-06ce86ff] {
  width: 52px;
  display: inline-block;
}
.icon-tiaoguo1[data-v-06ce86ff] {
  color: #17a2b8;
}
.style_form .el-form-item[data-v-06ce86ff] {
  margin-bottom: 10px;
}
.style_form .el-form-item .el-input[data-v-06ce86ff] {
  width: 140px;
  margin-left: 5px;
}
.icon-a-xinjianzidingyichicun21x1[data-v-06ce86ff] {
  font-size: 15px;
  margin-right: 2px;
}
.icon-jiantou-you[data-v-06ce86ff] {
  font-weight: 600;
}
.div_p[data-v-06ce86ff] {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-06ce86ff]:last-child {
  border-bottom: none;
}
.auto_content_size[data-v-06ce86ff] {
  width: 91%;
  margin-left: 10px;
}
.top_select_left[data-v-06ce86ff] {
  display: flex;
  align-items: center;
}
.hint_style[data-v-06ce86ff] {
  padding: 3px 12px;
  margin-left: 10px;
  font-size: 14px;
  background-color: #fdf6ec;
  color: #e6a23c;
  border-radius: 4px;
  cursor: pointer;
}
.hint_style .icon-guangbo[data-v-06ce86ff] {
  margin-right: 3px;
}
.set_style[data-v-06ce86ff] {
  text-decoration: underline;
}
.none-border p[data-v-06ce86ff] {
  line-height: 30px;
  text-align: left;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right_title[data-v-06ce86ff] {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.right_title h3[data-v-06ce86ff] {
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title h5[data-v-06ce86ff] {
  font-weight: normal;
}
.right_title p[data-v-06ce86ff] {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}
.content[data-v-06ce86ff] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content tr[data-v-06ce86ff] {
  padding: 0;
  margin: 0;
}
.content td[data-v-06ce86ff] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 28px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
}
.content .center[data-v-06ce86ff] {
  text-align: center;
}